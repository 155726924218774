import React, { useLayoutEffect, useEffect, useState } from 'react'
import clsx from 'clsx';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Highlight from 'react-highlight'
import hljs from 'highlight.js/lib/core';
import shell from 'highlight.js/lib/languages/shell';
import javascript from 'highlight.js/lib/languages/javascript';
import ruby from 'highlight.js/lib/languages/ruby';
import php from 'highlight.js/lib/languages/php';
import 'highlight.js/styles/stackoverflow-dark.css';

import './styles/styles.css';

const useStyles = makeStyles(theme => ({
    onePage: {
        maxWidth: '100%',
        minHeight: '87vh',
    },
    link: {
        textDecoration: 'none'
    },
    primaryPara: {
        textAlign: 'justify',
        // paddingLeft: '2em',
        // paddingRight: '2em',
    },
    primaryTitles: {
        paddingTop: '2em',
        // paddingLeft: '1.5em',
        color: '#16a09e'
        // color: '#156573'
        // color: '#24b47e'
    },
    subtitle: {
        color: '#156573'
    },
    title: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#156573'
    },
    paragraphs: {
        textAlign: 'justify'
    },
    container: {
        margin: '0.5em'
    },
    codeBlock: {
        margin: '0 auto',
        width: '100%',
        marginTop: 0,
        marginBottom: 0
    },
    languageChoices: {
        textAlign: 'justify',
        backgroundColor: '#1c1b1b',
        color: 'white',
    },
    languageChoice: {
        padding: '0.5em',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    selectedLanguageChoice: {
        border: '1px white solid',
        padding: '0.5em',
        '&:hover': {
            cursor: 'pointer'
        },
    }
}));

const GrabPay = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarTransparent } = props

    useEffect(() => {
        toggleNavbarTransparent(true)
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();
    },[])

    const [language, setLanguage] = useState('javascript')

    const changeLanguage = (targetLanguage) => {
        setLanguage(targetLanguage)
        switch (targetLanguage) {
            case 'javascript':
                hljs.registerLanguage('javascript', javascript);
                hljs.initHighlightingOnLoad();
                break;
            case 'ruby':
                hljs.registerLanguage('ruby', ruby);
                hljs.initHighlightingOnLoad();
                break;
            case 'php':
                hljs.registerLanguage('php', php);
                hljs.initHighlightingOnLoad();
                break;
            default:
                hljs.registerLanguage('javascript', javascript);
                hljs.initHighlightingOnLoad();
                break;
        }
    }

    return (<div className={classes.onePage}>
        <Grid container spacing={3} className={classes.container}>
            {/* Title */}
            <Grid item md={12} xs={12} sm={12}>
                <Typography className={classes.title} variant="h4" gutterBottom>GrabPay Implementation</Typography>
            </Grid>
            {/* Accordion */}
            <Grid item md={2} xs={12} sm={12}></Grid>
            <Grid item md={8} xs={12} sm={12}>
                {/* Initialize Liberalize */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Initialize Liberalize: Backend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Require NodeJS Backend SDK {'\n'}
                                const liberalize = require('liberalize-backend-nodejs-sdk');{'\n'}
                                {'//'} Initalize package, passing in your private key and environment {'\n'}
                                {'//'} 'prod' for live productionm 'staging' for test environment {'\n'}
                                const liberalizeBackEnd = new liberalize.LiberalizeNodeJs("your_private_key", "your_environment") {'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Require Ruby Backend SDK {'\n'}
                                require "liberalize_backend_ruby_sdk"{'\n'}
                                {'#'} Initalize package, passing in your private key and environment {'\n'}
                                {'#'} 'production' for live productionm 'staging' for test environment {'\n'}
                                liberalizeBackEnd = LiberalizeBackendRubySdk.new("your_private_key", "your_environment"){'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'//'} Require PHP Backend SDK {'\n'}
                                require __DIR__ . '/vendor/autoload.php';{'\n'}
                                use Liberalize\LiberalizeBackend;{'\n'}
                                {'//'} Initalize package, passing in your private key and environment {'\n'}
                                {'//'} 'production' for live productionm 'staging' for test environment {'\n'}
                                $liberalizeBackEnd = new LiberalizeBackend("your_private_key", "your_environment");{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Create Payment */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Create Payment: Backend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Create Payment {'\n'}
                                {'liberalizeBackEnd.createPayment({'}{'\n'}
                                {'\t'}{'amount: 100,'}{'//'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'currency: "SGD",'}{'\n'}
                                {'\t'}{'authorize: true, '}{'//'}{' recommended* A qrData parameter is in the response. It is required for the Frontend Module'}{'\n'}
                                {'\t'}{'source: "grabpay", '}{'//'}{'grabpay as an example'}{'\n'}
                                {'\t'}{'data: {, '}{'\n'}
                                {'\t'}{'\t'}{'grabpay: {, '}{'\n'}
                                {'\t'}{'\t'}{'\t'}{'redirectUrl: "https://your_domain.com/liberalize_redirect_page", '}{'\n'}
                                {'\t'}{'\t'}{'}'}{'\n'}
                                {'\t'}{'},'}{'\n'}
                                {'\t'}{'}, "{liberalize_service}").then((result) => { '}{'//'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                                {'\t'}{'console.log(result);'}{'\n'}
                                {'});'}{'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Create Payment {'\n'}
                                {'paymentSchema = liberalizeBackEnd.createPayment({'}{'\n'}
                                {'\t'}{'amount: 100,'}{'#'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'currency: "SGD",'}{'\n'}
                                {'\t'}{'authorize: true, '}{'#'}{' recommended* A qrData parameter is in the response. It is required for the Frontend Module'}{'\n'}
                                {'\t'}{'source: "grabpay", '}{'\n'}
                                {'\t'}{'data: {, '}{'\n'}
                                {'\t'}{'\t'}{'grabpay: {, '}{'\n'}
                                {'\t'}{'\t'}{'\t'}{'redirectUrl: "https://your_domain.com/liberalize_redirect_page", '}{'\n'}
                                {'\t'}{'\t'}{'}'}{'\n'}
                                {'\t'}{'},'}{'\n'}
                                {'}, "{liberalize_service}") '}{'#'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'//'} Create Payment {'\n'}
                                {'$newPaymentObj = ["amount"=>100,"source"=>"grabpay", "currency"=>"SGD", "authorize" => true, "data"=>["grabpay"=>["redirectUrl"=> "https://your_domain.com/liberalize_redirect_page"]]];'}{'\n'}
                                {'//'}{'authorize => true is Recommended* A qrData parameter is in the response. It is required for the Frontend Module'}{'\n'}{'\n'}
                                {"$paymentCreated = $liberalizeBackEnd->createPayment($newPaymentObj, '{liberalize_service}');"}{'\n'}
                                {'//'}{'liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Get Payment Status */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Get Payment Status: Backend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Get Payment Status {'\n'}
                                {'liberalizeBackEnd.getPaymentStatus("{paymentId}"),then((result) => { '}{'\n'}
                                {'\t'}{'console.log(result.data);'}{'\n'}
                                {'});'}{'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Get Payment Status {'\n'}
                                {'paymentSchema = liberalizeBackEnd.getPayment("{paymentId}") '}{'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'#'} Get Payment Status {'\n'}
                                {'$payment = $liberalizeBackEnd->getPayment("{paymentId}");'}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Initialize Liberalize: Frontend */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Initialize Liberalize: Web Frontend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                            </div>
                            {<Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Require Web SDK {'\n'}
                                const liberalize = require('liberalize-frontend-web-sdk');{'\n'}
                                {'//'} Initalize package, passing in your private key and environment {'\n'}
                                {'//'} 'prod' for live productionm 'staging' for test environment {'\n'}
                                const liberalizeFrontEnd = new liberalize.LiberalizeWeb("your_public_key", "staging") {'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Mount QR Code */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Mount QR Code: Web FrontEnd</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                            </div>
                            {<Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} GET QR Payment Scheme Logos {'\n'}
                                {'const supportedQrs = await liberalizeFrontEnd.qrSupported()'}{'\n'}
                                {'//'} sample response for supportedQrs: {'\n'}
                                {'//'} {'{ "grabpay": [ { "accountId": "5479a1ac-73c0-4537-a864-206718ea35a6", "source":"grabpay", "image":"https://payment-source-image.liberalize.io/adcbb770f61209613c3481424b24086c02776933.png" } ] }'} {'\n'}
                                {'//'} You only need one accountId / image to render on the front-end.{'\n'}
                                {'//'} Mount QR Elements Module {'\n'}
                                {'const elementResponse = await liberalizeFrontEnd.qrElement("id_of_html_element_to_mount_into", "qrData_from_backend", "grabpay", size_of_QR_element_in_pixels)'}{'\n'}
                                {'//'} qrData_from_backend is a parameter responsed when authorizing a payment from the backend SDK {'\n'}
                                {'//'} size_of_QR_element_in_pixels is an integer, the QR Code will be a square. Therefore, a single value is enough. {'\n'}
                                {'//'} Finally, Poll Payment Status on Backend, See 'Get Payment Status: Backend' {'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    onClick={() => { changeLanguage('kotlin') }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Mount QR Code: Android FrontEnd</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('kotlin') }} className={ language === 'kotlin' ? classes.selectedLanguageChoice : classes.languageChoice}>Kotlin</span>
                                <span onClick={() => { changeLanguage('java') }} className={ language === 'java' ? classes.selectedLanguageChoice : classes.languageChoice}>Java</span>
                            </div>
                            {language === 'kotlin' && <Highlight className={clsx('kotlin', classes.codeBlock)}>
                                {'//'} {'Initialize Liberalize'}{'\n'}
                                {'//'} {'set debug mode to see logs, false by default'}{'\n'}
                                {'Liberalize.enabledDebug = true'}{'\n'}
                                {'//'} {'initialize lib with key and env'}{'\n'}
                                {'Liberalize.init('}{'\n'}
                                {'\t'}{'"<public key>",'}{'\n'}
                                {'\t'}{'Liberalize.Env.STAGING'}{'\n'}
                                {')'}
                            </Highlight>}
                            {language === 'kotlin' && <Highlight className={clsx('kotlin', classes.codeBlock)}>
                                {'//'} {'Mount QRCode module'}{'\n'}
                                {'//'} This QrCodeView is to show QR code, you can put it into your layout {'\n'}
                                {'\n'}
                                {'//'} xml layout {'\n'}
                                {'<liberalize.kotlin.sdk.qr.QrCodeView'}{'\n'}
                                {'\t'}{'android:id="@+id/qr_view"'}{'\n'}
                                {'\t'}{'android:layout_width="<view size>"'}{'\n'}
                                {'\t'}{'android:layout_height="<view size>"'}{'\n'}
                                {'/>'}{'\n'}
                                {'\n'}
                                {'//'} or by adding following code {'\n'}
                                {'val qrCodeView = QrCodeView(context)'}{'\n'}
                                {'parent.addView(qrCodeView)'}{'\n'}
                                {'\n'}
                                {'//'} render QR code {'\n'}
                                {'qrCodeView.renderQrCode('}{'\n'}
                                {'\t'}{'QrCodeData('}{'\n'}
                                {'\t'}{'qrData = "<qrData",'}{'\n'}
                                {'\t'}{'source = "grabpay",'}{'\n'}
                                {'\t'}{'paymentId = "<paymentId>"'}{'\n'}
                                {'\t'}{')'}{'\n'}
                                {')'}{'\n'}
                                {'\n'}
                            </Highlight>}
                            {language === 'java' && <Highlight className={clsx('java', classes.codeBlock)}>
                                {'//'} {'set debug mode to see logs, false by default'}{'\n'}
                                {'Liberalize.setEnabledDebug(true);'}{'\n'}
                                {'//'} initialize lib with key and env{'\n'}
                                {'Liberalize.init('}{'\n'}
                                {'\t'}{'"<public key>",'}{'\n'}
                                {'\t'}{'Liberalize.Env.STAGING'}{'\n'}
                                {'\t'}{');'}
                            </Highlight>}
                            {language === 'java' && <Highlight className={clsx('java', classes.codeBlock)}>
                                {'//'} {'Mount QRCode module'}{'\n'}
                                {'//'} {'This QrCodeView is to show QR code, you can put it into your layout'}{'\n'}
                                {'\n'}
                                {'//'} {'xml layout'}{'\n'}
                                {'<liberalize.kotlin.sdk.qr.QrCodeView'}{'\n'}
                                {'\t'}{'android:id="@+id/qr_view"'}{'\n'}
                                {'\t'}{'android:layout_width="<view size>"'}{'\n'}
                                {'\t'}{'android:layout_height="<view size>"'}{'\n'}
                                {'/>'}{'\n'}
                                {'\n'}
                                {'//'} {'or by adding following code'}{'\n'}
                                {'QrCodeView qrCodeView = new QrCodeView(context);'}{'\n'}
                                {'parent.addView(qrCodeView);'}{'\n'}
                                {'\n'}
                                {'//'} {'render'}{'\n'}
                                {'qrCodeView.renderQrCode('}{'\n'}
                                {'\t'}{'new QrCodeData('}{'\n'}
                                {'\t'}{'"<qrData>",'}{'\n'}
                                {'\t'}{'"grabpay",'}{'\n'}
                                {'\t'}{'"<paymentId>"'}{'\n'}
                                {'\t'}{')'}{'\n'}
                                {');'}{'\n'}
                                {'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Mount QR Code: iOS FrontEnd</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('swift') }} className={ language === 'swift' ? classes.selectedLanguageChoice : classes.languageChoice}>Swift</span>
                            </div>
                            {<Highlight className={clsx('swift', classes.codeBlock)}>
                                {'//'} The‌re a‌re 3 types o‌f eviroment: dev, staging, prod {'\n'}
                                {'//'} Initialize Liberalize{'\n'}
                                {'const supportedQrs = await liberalizeFrontEnd.qrSupported()'}{'\n'}
                                {'Libralize.shared.setEnvironment(<environment>)'}{'\n'}
                                {'Libralize.shared.register(publicKey: "<public key>")'}{'\n'}
                                {'\n'}
                                {'//'} {'QRCodeView show the QR Code that user can scan to proceed payment.'}{'\n'}
                                {'//'} {'The QRCodeView requires 2 parameters are QR data and payment source, the QRCode size is and optional with default value is 256.'}{'\n'}
                                {'//'} {'It can be used like other UIView components.'}{'\n'}
                                {'let qrCodeView = QRCodeView(qrData: "<QR data>", source: "grabpay", size: <QR_code_size>)'}{'\n'}
                                {'//'} OR {'\n'}
                                {'let qrCodeView = QRCodeView(size: <QR code size>)'}{'\n'}
                                {'qrCodeView.loadQR(qrData: "<QR data>", source: "grabpay")'}{'\n'}
                                {'...'}{'\n'}
                                {'view.addSubview(qrCodeView)'}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item md={2} xs={12} sm={12}></Grid>
        </Grid>
    </div>)
}

export default GrabPay