import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { drawerWidth } from '../app/globals';
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    navbar: {
        backgroundColor: 'rgba(255,255,255,1)'
    },
    navbarWhite: {
        backgroundColor: 'rgba(255,255,255,1)'
    },
    darkGreen:{
        color: '#156573'
    },
    white:{
        color: '#156573'
    },
    link:{
        color: '#156573',
        textDecoration: 'none'
    },
    navbar_logo: {
        maxWidth: '10em'
    },
    title: {
        color: '#156573',
        flexGrow: 1,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    Btn: {
        border: '1.2em',
        borderColor: '#156573',
    }
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
  }

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const Appbar = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, originalNavState } = props
    let [originalNav, setoriginalNav] = useState(false)
        useEffect(() => {
            setoriginalNav(originalNavState)
          },[originalNavState])
    let [mobileView, setmobileView] = useState(false)
    window.onscroll = function(e) {
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) * 0.5
        const pageYOffSet = window.pageYOffset
        if (originalNavState) {
            setoriginalNav(window.location.pathname === '/' && pageYOffSet < viewHeight)        
        }
    }
    const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

    useEffect(()=> {
        setmobileView(viewWidth > 768)
    })

    return <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
            <AppBar className={clsx((originalNav ? classes.navbar : classes.navbarWhite), {
                    [classes.appBarShift]: openDrawer,
            })}>
                <Toolbar>
                    <IconButton 
                        className={originalNav ? classes.white : classes.darkGreen} 
                        color="inherit" 
                        aria-label="Menu"
                        onClick={() => toggleDrawer()}
                    >
                        <MenuIcon />
                    </IconButton>
                    { originalNav ? 
                        <Link className={classes.link} to={'/'}><Typography variant="h4"><b>Liberalize Dev Docs</b></Typography></Link>:
                        <Link className={classes.link} to={'/'}><img className={classes.navbar_logo} src={require("../assets/logo_n_text.png")} alt="Liberalize"/></Link>
                    }
                    <Typography variant="h6" className={classes.title}/>


                    <Typography variant="h6" className={classes.title}/>
                    
                    <a className={classes.link} href='https://console.liberalize.io'>
                            <Button 
                                variant="outlined" 
                                className={clsx(originalNav ? classes.white : classes.darkGreen, classes.Btn)}
                                endIcon={<ArrowForwardIcon/>}
                            >
                                <Typography variant="subtitle1">
                                    Console
                                </Typography>
                            </Button>
                    </a>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
        <Toolbar />
        </React.Fragment>
}

Appbar.propTypes = {
    openDrawer: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired
}

export default Appbar
