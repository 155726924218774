import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom"
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import DevicesIcon from '@material-ui/icons/Devices';
import PaymentIcon from '@material-ui/icons/Payment';
import CodeIcon from '@material-ui/icons/Code';
// import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AppsIcon from '@material-ui/icons/Apps';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { useHistory } from "react-router-dom";

import { drawerWidth } from '../app/globals';

const useStyles = makeStyles((theme => ({
      link:{
        color: '#156573',
        textDecoration: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
})));

const AppDrawer = (props) => {
    const history = useHistory();
    const classes = useStyles(props)
    const theme = useTheme();
    const { openDrawer, toggleDrawer } = props
    const [cardHover, setCardHover] = useState(false)
    const [cardDropDown, setCardDropDown] = useState(false)
    const [qrHover, setQrHover] = useState(false)
    const [qrDropDown, setQrDropDown] = useState(false)

    const goToSection = (text) => {
      // console.log('text is -> ', text);
      let destination = ''
      switch (text) {
        case 'card-backend':
          destination = 'card-backend'
          break;
        case 'card-web':
          destination = 'card-web'
          break;
        case 'qr-paynow':
          destination = 'qr-paynow'
          break;
        case 'qr-grabpay':
          destination = 'qr-grabpay'
          break;
        default:
          destination = text
          break;
      }
      history.push({
        pathname: `/${destination}`,
      })
    }

    return (<Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
    >
        <div className={classes.drawerHeader}>
            <IconButton onClick={() => toggleDrawer()}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </div>
        <Divider />
        <List>
          {/* Introduction */}
          <Link key={'introduction'} className={classes.link} to={`/introduction`}>
            <ListItem button key={'Introduction'}>                
              <ListItemText primary={'Introduction'} />
            </ListItem>
          </Link>
          <Divider className={classes.divider} light={true}/>
          {/* Card */}
          <ListItem className={classes.link} button key={'Card'} button onMouseEnter={() => {setCardHover(true)}} onMouseLeave={() => {setCardHover(false)}} onClick={() => {setCardDropDown(!cardDropDown)}}>
            <ListItemText primary={'Card Payments'} />
            {cardHover && (cardDropDown ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={cardDropDown} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {['Backend', 'Web'].map((text) => {
                return <ListItem className={classes.link} onClick={(e) => { goToSection('card-'+text.toLowerCase()) }} button key={text}>
                  <ListItemText primary={text} />
                </ListItem>
              })}
            </List>
          </Collapse>
          <Divider className={classes.divider} light={true}/>
          {/* QR Payments */}
          <ListItem className={classes.link} button key={'QR'} onMouseEnter={() => {setQrHover(true)}} onMouseLeave={() => {setQrHover(false)}} onClick={() => {setQrDropDown(!qrDropDown)}}>
            <ListItemText primary={'QR Payments'} />
            {qrHover && (qrDropDown ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={qrDropDown} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {['Paynow', 'GrabPay', 'ShopeePay', 'AliPayQR', 'WeChatPayQR'].map((text) => {
                return <ListItem className={classes.link} onClick={(e) => { goToSection('qr-'+text.toLowerCase()) }} button key={text}>
                  <ListItemText primary={text} />
                </ListItem>
              })}
            </List>
          </Collapse>
          <Divider className={classes.divider} light={true}/>
        </List>
      </Drawer>)
}

export default AppDrawer
