import { connect } from 'react-redux'
import { toggleDrawer } from '../actions/drawers'
import { toggleNavbarWhite, toggleNavbarTransparent } from '../actions/navbar'
import Introduction from '../components/Introduction'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    originalNavState: (state.navBar && state.navBar.originalNav) || false
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleNavbarWhite: () => dispatch(toggleNavbarWhite()),
    toggleNavbarTransparent: () => dispatch(toggleNavbarTransparent()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Introduction)