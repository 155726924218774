import React, { useEffect, useState, Suspense } from 'react'
import clsx from 'clsx';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Highlight from 'react-highlight'
import hljs from 'highlight.js/lib/core';
import shell from 'highlight.js/lib/languages/shell';
import gradle from 'highlight.js/lib/languages/gradle';
import swift from 'highlight.js/lib/languages/swift';
import 'highlight.js/styles/stackoverflow-dark.css';

import { Html, Environment, ContactShadows, useProgress } from '@react-three/drei';

import { useSpring } from '@react-spring/core'
import { a as three } from '@react-spring/three'
import { a as web } from '@react-spring/web'

import { Canvas } from '@react-three/fiber'

import styled from 'styled-components';

import Globe from './Globe'
import Laptop from './Laptop'

import './styles/styles.css'


const useStyles = makeStyles(theme => ({
    onePage: {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
    },
    link: {
        textDecoration: 'none'
    },
    primaryPara: {
        textAlign: 'justify',
        // paddingLeft: '2em',
        // paddingRight: '2em',
    },
    primaryTitles: {
        paddingTop: '2em',
        // paddingLeft: '1.5em',
        color: '#16a09e'
        // color: '#156573'
        // color: '#24b47e'
    },
    subtitle: {
        color: '#156573'
    },
    title: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#156573'
    },
    paragraphs: {
        textAlign: 'justify'
    },
    container: {
        maxWidth: '100%',
        margin: '0.5em',
    },
    codeBlock: {
        margin: '0 auto',
        width: '100%'
    },
    threeWorld: {
        minHeight: '100vh',
        [theme.breakpoints.up('md')]: {
            minHeight: '-10vh',
        },
    },
    arrow1: {
        textAlign: 'center',
        marginTop: '-14vh'
    },
    arrow2: {
        textAlign: 'center',
        marginBottom: '14vh'
    },
    suits: {
        fontSize: '0.7em',
        letterSpacing: '-0.02em'
    },
    loadingText: {
        fontSize: '0.4em',
        color: '#156573',
        letterSpacing: '0.02em'
    }
}));


const Introduction = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarTransparent } = props


    useEffect(() => {
        toggleNavbarTransparent(true)
        hljs.registerLanguage('swift', swift);
        hljs.registerLanguage('gradle', gradle);
        hljs.registerLanguage('shell', shell);
        hljs.initHighlightingOnLoad();
    },[])

    function Loader() {
        const { active, progress, errors, item, loaded, total } = useProgress();
        return <Html center style={{color: '#24b47e', fontWeight: '700'}}>{progress.toFixed(2)}%</Html>
    }

    // This flag controls open state, alternates between true & false
    const [open, setOpen] = useState(false)
    // We turn this into a spring animation that interpolates between 0 and 1
    const springProps = useSpring({ open: Number(open) })

    return (<div className={classes.onePage}>
        <web.div style={{ background: springProps.open.to([0, 1], ['#fafafa', '#00fdab']) }}>
            <web.h2 style={{ opacity: springProps.open.to([0, 1], [1, 0]), transform: springProps.open.to((o) => `translate3d(-50%,${o * 50 - 100}px,0)`) }}>
                Ok, hot shot. <br/> Fire up this laptop. <span className={classes.suits}>#suits</span>
            </web.h2>
            <Canvas className={classes.threeWorld} dpr={[1, 2]} camera={{ position: [0, 0, 0], fov: 35 }}>
                <three.pointLight position={[10, 10, 10]} intensity={1.5} color={springProps.open.to([0, 1], ['#fafafa', '#00fdab'])} />
                <Suspense fallback={<Loader />}>
                    <group rotation={[0, Math.PI, 0]} onClick={(e) => (e.stopPropagation(), setOpen(!open))}>
                        <Laptop open={open} hinge={springProps.open.to([0, 1], [1.575, -0.425])} />
                    </group>
                    <Environment preset="city" />
                </Suspense>
                <ContactShadows rotation-x={Math.PI / 2} position={[0, -4.5, 0]} opacity={0.4} width={20} height={20} blur={2} far={4.5} />
            </Canvas>
        </web.div>
        {open && <div className={classes.arrow1}>
            <KeyboardArrowDownIcon />
        </div>}
        {open && <div className={classes.arrow2}>
            <KeyboardArrowDownIcon />
        </div>} 
        {open && <Grid container spacing={3} className={classes.container}>
            {/* Title */}
            <Grid item md={12} xs={12} sm={12}>
                <Typography className={classes.title} variant="h4" gutterBottom>Introduction</Typography>
            </Grid>
            {/* Paragraph */}
            <Grid item md={2} xs={12} sm={12}></Grid>
            <Grid item md={8} xs={12} sm={12}>
                <Typography className={classes.paragraphs} variant="h7" gutterBottom>
                    This site does not provide the documents for Payment Terminals (e.g. WeChat QR Payment). You will need to implement a backend SDK 
                    and a choice of your desired fontend SDK to complete a full payment flow.
                </Typography>
            </Grid>
            <Grid item md={2} xs={12} sm={12}></Grid>
            {/* Installation Guide */}
            <Grid item md={2} xs={12} sm={12}></Grid>
            <Grid item md={8} xs={12} sm={12}>
                <Typography className={classes.subtitle} variant="h7" gutterBottom>Installation Guide</Typography>
            </Grid>
            <Grid item md={2} xs={12} sm={12}></Grid>
            {/* Accordion */}
            <Grid item md={2} xs={12} sm={12}></Grid>
            <Grid item md={8} xs={12} sm={12}>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>NodeJS Backend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                $ npm install liberalize-backend-nodejs-sdk{'\n'} 
                            </Highlight>
                            OR
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                $ yarn add liberalize-backend-nodejs-sdk{'\n'}
                            </Highlight>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Ruby Backend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                $ gem install liberalize_backend_ruby_sdk{'\n'} 
                            </Highlight>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Php Backend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                $ composer require liberalize/liberalize-backend-php-sdk #current latest version should be 0.1.1{'\n'}
                                
                            </Highlight>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Web Frontend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                $ npm install liberalize-frontend-web-sdk{'\n'} 
                            </Highlight>
                            OR
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                $ yarn add liberalize-frontend-web-sdk{'\n'}
                            </Highlight>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Android Frontend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <Highlight className={clsx("gradle", classes.codeBlock)}>
                                {'//'}Before you add Stream dependencies, update your repositories in the settings.gradle file to include these repositories:{'\n'}
                                {"dependencyResolutionManagement {"}{'\n'}
                                {'\t'}{'repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)'}{'\n'}
                                {'\t'}{'repositories {'}{'\n'}
                                {'\t'}{'\t'}{'google()'}{'\n'}
                                {'\t'}{'\t'}{'mavenCentral() // add this'}{'\n'}
                                {'\t'}{'}'}{'\n'}
                                {'}'}{'\n'}
                            </Highlight>
                                OR if you're using an older project setup, add these repositories in your project level build.gradle file:
                            <Highlight className={clsx("gradle", classes.codeBlock)}>
                            {'allprojects {'}{'\n'}
                            {'\t'}{'repositories {'}{'\n'}
                            {'\t'}{'\t'}{'google()'}{'\n'}
                            {'\t'}{'\t'}{'mavenCentral() // add this'}{'\n'}
                            {'\t'}{'}'}{'\n'}
                            {'}'}
                            </Highlight>
                            To add the library to your app, open your module's build.gradle script and add the following:
                            <Highlight className={clsx("gradle", classes.codeBlock)}>
                                {'dependencies {'}{'\n'}
                                {'\t'}{'implementation "io.liberalize:liberalize-frontend-android-sdk:0.0.1"'}{'\n'}
                                {'}'}
                            </Highlight>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>iOS Frontend</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            Liberalize-Frontend-iOS-SDK is available through CocoaPods. To install it, simply add the following line to your Podfile:
                            <Highlight className={clsx("swift", classes.codeBlock)}>
                                {"source 'https://github.com/CocoaPods/Specs.git'"}{'\n'}
                            </Highlight>
                                OR if you're using an older project setup, add these repositories in your project level build.gradle file:
                            <Highlight className={clsx("swift", classes.codeBlock)}>
                            {'//'} {"Put pod 'Liberalize-Frontend-iOS-SDK' into your target"}{'\n'}
                            {'target <Your_ta‌rget> do'}{'\n'}
                                {'\t'}{"pod 'Liberalize-Frontend-iOS-SDK'"}{'\n'}
                            {'end'}
                            </Highlight>
                            Open terminal, navigate to project's root directory, and run
                            <Highlight className={clsx("shell", classes.codeBlock)}>
                                {'pod install'}
                            </Highlight>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item md={2} xs={12} sm={12}></Grid>
        </Grid>}
    </div>)
}

export default Introduction