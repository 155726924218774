import React from 'react';
// import './App.css';
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import clsx from 'clsx';

import NoMatch from './containers/NoMatch'
import Introduction from './containers/Introduction'
import CardBackend from './containers/CardBackend'
import CardWeb from './containers/CardWeb'
import Paynow from './containers/Paynow'
import GrabPay from './containers/GrabPay'
import ShopeePay from './containers/ShopeePay'
import AliPayQR from './containers/AliPayQR'
import WeChatPayQR from './containers/WeChatPayQR'
import ComingSoon from './containers/ComingSoon'
import PrivacyPolicy from './containers/PrivacyPolicy'
// import GettingStarted from './containers/GettingStarted'
import Terms from './containers/Terms'
import { drawerWidth } from './app/globals';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: +drawerWidth,
  },
}));

function App(props) {
  const classes = useStyles(props);
  const { openDrawer } = props
  return (
    <ThemeProvider theme={theme}>
    <main 
      className={clsx(classes.content, {
        [classes.contentShift]: openDrawer,
      })}
    >
      <Switch>
        <Route path="/" exact component={Introduction}/>
        <Route path="/introduction" exact component={Introduction}/>
        <Route path="/card-backend" exact component={CardBackend}/>
        <Route path="/card-web" exact component={CardWeb}/>
        <Route path="/qr-paynow" exact component={Paynow}/>
        <Route path="/qr-grabpay" exact component={GrabPay}/>
        <Route path="/qr-shopeepay" exact component={ShopeePay}/>
        <Route path="/qr-alipayqr" exact component={AliPayQR}/>
        <Route path="/qr-wechatpayqr" exact component={WeChatPayQR}/>
        {/* <Route path="/qr-grabpay" exact component={NoMatch}/> */}
        {/* <Route path="/support" exact component={ComingSoon}/> */}
        {/* <Route path="/getting-started" exact component={GettingStarted}/> */}
        <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
        <Route path="/terms" exact component={Terms}/>
        <Route component={NoMatch} />
      </Switch>
    </main>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  openDrawer: state.navDrawers.isOpen
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)