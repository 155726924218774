import React from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FavoriteIcon from '@material-ui/icons/Favorite'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Link } from "react-router-dom"

import { drawerWidth } from '../app/globals';

const useStyles = makeStyles((theme) => ({
    footerGreen: {
        position: 'relative',
        display: 'block',
        bottom: 0,
        width: '100%',
        backgroundColor: '#156573',
        minHeight: '100px',
        color: 'white',
    },
    heart: {
        color : '#54B454',
        fontWeight: 900,
        verticalAlign: 'middle'
    },
    content: {
        paddingTop: '2em',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerInfo: {
        color: 'white',
        padding: '0 0.5em',
        textDecoration: 'none'
    },
    footerIcon: {
        color : '#54B454',
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
      footerShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const Footer = (props) => {
    const classes = useStyles(props)
    // const { openDrawer } = props
    return (<div className={clsx(classes.footerGreen)}>
    <div>
        <Grid container 
            justify="center" 
            alignContent="center" 
            alignItems="center" 
            direction="row"
            className={classes.content}
        >
            <Grid item xs={8} sm={4} md={3}>
                <Typography variant="h6">
                    <Link className={classes.footerInfo} onClick={(e) => {
                        e.preventDefault()
                        window.open('mailto:support@liberalize.io?subject=RE:Feedback')
                    }}><a className={classes.footerInfo} href="mailto:support@liberalize.io">Feedback</a></Link>
                    <Link className={classes.footerInfo} to="/privacy-policy">Privacy</Link>
                    <Link className={classes.footerInfo} to="/terms">Terms</Link>
                </Typography>
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
                <Typography variant="h6">Made with <FavoriteIcon className={classes.heart}/> in Singapore, SG</Typography>
            </Grid>
            <Grid item xs={8} sm={4} md={3} className={classes.introImage}>
                <Link 
                    className={classes.link}  
                    href={'https://www.instagram.com/liberalizeio'}
                    onClick={(e) => {
                        e.preventDefault()
                        window.open('https://www.instagram.com/liberalizeio')
                    }}
                >
                    <InstagramIcon className={classes.footerIcon}/>
                </Link>
                <Link 
                    className={classes.link} 
                    href={'https://www.youtube.com/channel/UC6z16oVp9rf7F7yEB51hStQ'}
                    onClick={(e) => {
                        e.preventDefault()
                        window.open('https://www.youtube.com/channel/UC6z16oVp9rf7F7yEB51hStQ')
                    }}
                >
                    <YouTubeIcon className={classes.footerIcon}/>
                </Link>
                {/* <Link className={classes.link} href={'https://github.com/LiberationNetwork'}><GitHubIcon className={classes.footerIcon}/></Link> */}
            </Grid>
        </Grid>
    </div>
</div>)
}

// Footer.propTypes = {
//     openDrawer: PropTypes.bool.isRequired,
// }

export default Footer
