import React, { useLayoutEffect, useEffect, useState } from 'react'
import clsx from 'clsx';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Highlight from 'react-highlight'
import hljs from 'highlight.js/lib/core';
import shell from 'highlight.js/lib/languages/shell';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/stackoverflow-dark.css';

import './styles/styles.css';

const useStyles = makeStyles(theme => ({
    onePage: {
        maxWidth: '100%',
        minHeight: '87vh',
    },
    link: {
        textDecoration: 'none'
    },
    primaryPara: {
        textAlign: 'justify',
        // paddingLeft: '2em',
        // paddingRight: '2em',
    },
    primaryTitles: {
        paddingTop: '2em',
        // paddingLeft: '1.5em',
        color: '#16a09e'
        // color: '#156573'
        // color: '#24b47e'
    },
    subtitle: {
        color: '#156573'
    },
    title: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#156573'
    },
    paragraphs: {
        textAlign: 'justify'
    },
    container: {
        margin: '0.5em'
    },
    codeBlock: {
        margin: '0 auto',
        width: '100%',
        marginTop: 0,
        marginBottom: 0
    },
    languageChoices: {
        textAlign: 'justify',
        backgroundColor: '#1c1b1b',
        color: 'white',
    },
    languageChoice: {
        padding: '0.5em',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    selectedLanguageChoice: {
        border: '1px white solid',
        padding: '0.5em',
        '&:hover': {
            cursor: 'pointer'
        },
    }
}));

const CardWeb = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarTransparent } = props

    useEffect(() => {
        toggleNavbarTransparent(true)
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();
    },[])

    const [language, setLanguage] = useState('javascript')

    const changeLanguage = (targetLanguage) => {
        setLanguage(targetLanguage)
        switch (targetLanguage) {
            case 'javascript':
                hljs.registerLanguage('javascript', javascript);
                hljs.initHighlightingOnLoad();
                break;
            default:
                hljs.registerLanguage('javascript', javascript);
                hljs.initHighlightingOnLoad();
                break;
        }
    }

    return (<div className={classes.onePage}>
        <Grid container spacing={3} className={classes.container}>
            {/* Title */}
            <Grid item md={12} xs={12} sm={12}>
                <Typography className={classes.title} variant="h4" gutterBottom>Card Web Implementation</Typography>
            </Grid>
            {/* Accordion */}
            <Grid item md={2} xs={12} sm={12}></Grid>
            <Grid item md={8} xs={12} sm={12}>
                {/* Initialize Liberalize */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Initialize Liberalize</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Require Web SDK {'\n'}
                                const liberalize = require('liberalize-frontend-web-sdk');{'\n'}
                                {'//'} Initalize package, passing in your private key and environment {'\n'}
                                {'//'} 'prod' for live productionm 'staging' for test environment {'\n'}
                                const liberalizeFrontEnd = new liberalize.LiberalizeWeb("your_public_key", "staging") {'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Mount Card Elements Module */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Mount Card Elements Module</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Mount Card Elements Module {'\n'}
                                {'const elementResponse = await liberalizeFrontEnd.cardElement("id_of_html_element_to_mount_into")'}{'\n'}
                                {'if (elementResponse && elementResponse.initialLoad) {'}{'\n'}
                                {'\t'}{'//'}{' Set any loading state to be done'}{'\n'}
                                {'} '}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Pay Event */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>PAY Event</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'// '} Pay Event, this is usually an on-click event by a pay button {'\n'}
                                {'const response = await liberalizeCls.cardElementPay()'}{'\n'}
                                {'// '} The response consist of the paymentMethodId required for authorization{'\n'}
                                {'console.log(result);'}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item md={2} xs={12} sm={12}></Grid>
        </Grid>
    </div>)
}

export default CardWeb